<template>
  <v-container :fluid="$vuetify.breakpoint.smAndUp" min-height="90vh" class="pa-0">
    <v-card class="mt-3 fill-height" style="min-height: 85vh" v-show="!loading" color="transparent">
      <v-card-title class="white">
        <v-btn :to="{name:'Home'}" icon class="mr-3">
          <pg-icon XL>i-go-back</pg-icon>
        </v-btn>&nbsp;Communauté
      </v-card-title>
      <v-card-text class="pa-0">
        <game-filter class="mb-1" v-model="selectedGameFilters"/>
        <v-row no-gutters v-if="gamesByType.length">
          <v-col cols="12" sm="6" v-for="game in gamesByType" v-bind:key="game.id" class="pa-1">
            <GameCard :game="game" class="mb-2"></GameCard>
          </v-col>
        </v-row>
        <v-row no-gutters v-else justify="center" align="center" style="min-height: 50vh">
          <v-card max-width="90%" class="mt-5">
            <v-card-title class="text-center">Il n'y a pas encore de jeux pour votre sélection. Mais cela ne saurait
              tarder...
            </v-card-title>
            <v-img height="500" contain src="~@/assets/oops.png" max-width="1280px"></v-img>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row align="center" justify="center">
      <v-progress-circular class="ma-auto" indeterminate color="purple" size="50" v-if="loading"/>
    </v-row>
    <game-pagination class="mt-12"/>
  </v-container>
</template>

<script>
import GamePagination from "../../components/game/GamePagination";
import PgIcon from "../../components/PGIcon";
import GameFilter from "../../components/game/GameFilter";
import GameCard from "../../components/game/GameCard";
import GameMixin from "../../mixins/GameMixin";

export default {
  name: "Community",
  components: {GamePagination, PgIcon, GameFilter, GameCard},
  mixins: [GameMixin],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
  },
  created() {
    console.log('created com')
    this.loadGames();
  },
  methods:{
    async loadGames() {
      if (!this.loading) {
        this.loading = true;
        this.loadCommunityGames().finally(() => {
          setInterval(() =>{
            this.loading = false;
          },300)
        })
      }
    }
  },
  watch: {
    $route: function (){
      if(this.$route.name==='Community'){
        this.loadGames()
      }
    },
    selectedGameFilters: function () {
      this.loadGames();
    },


  }
}
</script>

<style scoped>

</style>